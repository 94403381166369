<template>
  <auth-layout>
    <template v-slot:title>
      <span>{{ $t("create") }}</span>
      <span>{{ $t("account") }}</span>
    </template>
    <template v-slot:subtitle>
      <span>{{ $t("auth_form_create_account_text") }}</span>
    </template>
    <template v-slot:form>
      <v-form ref="form" class="" lazy-validation @submit.prevent="userSignUp">
        <!-- email -->
        <v-text-field
          class="form-input"
          v-model.trim="form.email"
          :label="$t('email')"
          :rules="[(val) => rules.email(val) || $t('auth_form_invalid_email')]"
          validate-on-blur
          @focus.stop="hideNotification"
          @focusout="checkUser"
          id="email"
          data-testid="email"
        ></v-text-field>
        <div class="user-name">
          <v-text-field
            class="form-input"
            v-model.trim="form.firstName"
            :label="$t('first_name')"
            :rules="[
              () => !!form.firstName || $t('auth_form_invalid_first_name'),
            ]"
            validate-on-blur
            id="first-name"
            data-testid="first-name"
          ></v-text-field>
          <v-text-field
            class="form-input"
            v-model.trim="form.lastName"
            :label="$t('last_name')"
            :rules="[
              () => !!form.lastName || $t('auth_form_invalid_last_name'),
            ]"
            validate-on-blur
            id="last-name"
            data-testid="last-name"
          ></v-text-field>
        </div>
        <div class="form-terms">
          <span>{{ $t("auth_form_terms_line") }}</span>
          <div class="terms-links">
            <a
              href="https://www.spartan.com/en/about/privacy-policy"
              target="_blank"
              class="terms-link"
              >{{ $t("privacy_policy") }}</a
            >
            <span class="links-divider">{{ $t("and") }}</span>
            <a
              href="https://www.spartan.com/en/about/terms-of-service"
              target="_blank"
              class="terms-link"
              >{{ $t("terms_of_use") }}.</a
            >
          </div>
        </div>
        <div class="form-buttons">
          <mem-button
            type="submit"
            btnType="secondary-dark"
            :disabled="disableBtn"
            :loading="loading"
          >
            {{ $t("sign_up_and_continue") }}
          </mem-button>
        </div>
      </v-form>
    </template>
    <template v-slot:footer>
      <span>{{ $t("already_have_account") }}</span>
      <span class="text-link" @click="goToSignIn">{{ $t("sign_in") }}</span>
    </template>
  </auth-layout>
</template>
<script>
import { emailValidator } from "@/common/validators";
import { mapActions } from "vuex";
import mixinAuthForms from "./mixinAuthForms";
export default {
  name: "FormSignUpFacebook",
  mixins: [mixinAuthForms],
  components: {
    "auth-layout": () => import("./AuthLayout.vue"),
    "mem-button": () => import("@/components/base/BaseButton.vue"),
  },
  data: () => ({
    form: {
      email: "",
      firstName: "",
      lastName: "",
    },
    rules: {
      email: emailValidator,
    },
    loading: false,
  }),
  computed: {
    disableBtn() {
      let { email, firstName, lastName } = this.form;
      return (
        !emailValidator(email) ||
        !(firstName.length > 0) ||
        !(lastName.length > 0)
      );
    },
  },
  methods: {
    ...mapActions([
      "checkUserExist",
      "socialSignUp",
      "handleToolbarNotification",
    ]),
    async userSignUp() {
      let { email, firstName, lastName } = this.form;
      let { lang, country, oauth_token } = this.$route.query;
      this.loading = true;

      let data = {
        email: email,
        first_name: firstName,
        last_name: lastName,
      };
      if (lang) data.language = lang;
      if (country) data.spartan_country_code = country;

      try {
        let result = await this.socialSignUp({
          data,
          redirect: this.redirectUrl,
          oauthToken: oauth_token,
        });
        window.location = result;
        // console.log("result", result);
      } catch (e) {
        this.loading = false;
        if (e.multipass) this.$router.push("home");
        if (e.exists) this.showExistNotification();
        console.log(e);
      }
    },
    goToSignIn() {
      this.hideNotification();
      this.$router.push({ name: "formSignIn", query: this.$route.query });
    },
    async checkUser() {
      if (!this.form.email) return;
      try {
        let user = await this.checkUserExist(this.form.email);
        if (user.exists && this.$route.name === "formSignUpFacebook")
          this.showExistNotification();
      } catch (err) {
        console.log("", err);
      }
    },
    showExistNotification() {
      this.handleToolbarNotification({
        isVisible: true,
        body: "auth",
        data: {
          email: this.form.email,
        },
      });
    },
    hideNotification() {
      this.handleToolbarNotification({ isVisible: false });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let { first_name, last_name, oauth_token } = vm.$route.query || {};

      if (!oauth_token) return next({ name: "formLogin" });

      if (first_name) vm.form.firstName = first_name;
      if (last_name) vm.form.lastName = last_name;
    });
  },
};
</script>

<style lang="scss" scoped>
.user-name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  @media screen and (max-width: 499px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}
.form-terms {
  // margin-bottom: 48px;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
  opacity: 0.4;
  @media screen and (max-width: 499px) {
    // margin-bottom: 44px;
  }
  .terms-links {
    .links-divider {
      margin: 0 5px;
    }
    .terms-link {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 2px;
      cursor: pointer;
      outline: none;
    }
  }
}

.form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 34px;
  @media screen and (max-width: 499px) {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  button {
    width: 100%;
  }
}
</style>